import React from 'react';
import { string } from 'prop-types';
import SearchForCard from 'components/cards/loyalty/search';
import ScanQRCodeCard from 'components/cards/loyalty/qr-code';
import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const ActionScanLoyaltyView = ({ passId, title }) => {
  const styles = useStyles();

  return (
        <div className={styles.menucontent}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            <Grid item md={6} sm={6} xs={12}>
              <ScanQRCodeCard passId={passId} title={title}/>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SearchForCard passId={passId} title={title}/>
            </Grid>
          </Grid>
        </div>
  );
};

ActionScanLoyaltyView.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default ActionScanLoyaltyView;
