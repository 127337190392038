import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { LOYALTY_SEARCH_ROUTE } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faSearch as solidFaSearch } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const SearchForCard = ({ passId, title }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${LOYALTY_SEARCH_ROUTE}?passId=${passId}`)}
          title="Search"
          description={`Search for a "${title}" card`}
          buttonMessage="Search"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faSearch} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={solidFaSearch} />
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

SearchForCard.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default SearchForCard;
